



// $(".hidden-block").on("mouseenter", function () {
//     $(this).attr("data-menu")
//     $(".menu-svg").removeClass("active")
//     $(".wrapper-text-block").removeClass("active")
//     $(`.menu-svg[id='${$(this).attr("data-menu")}']`).addClass("active")
//     $(`.wrapper-text-block[data-menu='${$(this).attr("data-menu")}']`).addClass("active")
// })

$(window).on("scroll", function () {
    let scroll = $(window).scrollTop()
    // console.log(scroll)
    // if (scroll > 0) {
    //     $(".wrapper-welcome-text").addClass("animate__animated animate__fadeInDown")
    // }

    if (scroll > 300) {
        $("#content-general-page-1").addClass("animate__animated animate__fadeInRight")
    }

    if (scroll > 600) {
        $("#content-general-page-2").addClass("animate__animated animate__fadeInLeft")
    }
})


let slinkyMenu
$("#burger-for-open").on("click", function () {
    $("#menu-burger").addClass("active")
    slinkyMenu = $("#slinky-menu").slinky({
        title: true
    })
    $("body").addClass("overflow")
})

$("#burger-for-close").on("click", function () {
    $("#menu-burger").removeClass("active")
    $("body").removeClass("overflow")
})


$(".link-slinky-menu").on("click", function () {
    $("#menu-burger").removeClass("active")
    $("body").removeClass("overflow")
})


// $(".wrapper-section-lpm[data-menu-lpm]").on("mouseenter", function () {
//     $(".main-logo-lpm").removeClass("active")
//     $(".wrapper-section-lpm").removeClass("active")
//     $(this).addClass("active")
//
//     let dataAttr = $(this).attr("data-menu-lpm")
//     $(`.wrapper-player`).removeClass("active")
//     $(`.wrapper-player[data-menu-lpm='${dataAttr}']`).addClass("active")
//     $(".logo-lpm-for-section").removeClass("active")
//     $(this).find(".logo-lpm-for-section").addClass("active")
// })


$(function () {
    $(".description-content").each((ind, elem) => {
        $(elem).attr("data-height", $(elem).height())
    })
})


let sectionLPM = $(".section-lpm")
sectionLPM.on("mouseenter", function () {
    // $(".video-lpm").each((ind, elem) => {
    //     elem.pause()
    //     $(elem).addClass("black-white")
    // })
    // $(this).find(".description-content").attr("data-height", $(this).find(".description-content").height())
    // $(this).find(".video-lpm").removeClass("black-white")
    $(this).find(".video-lpm")[0].play()
    $(this).find(".img-section-lpm").removeClass("active")


    if (window.innerWidth > 1400) {
        $(this).find(".description-content").animate({
            height: 0
        }, {duration: 500, queue: false}, () => {
        })
    }


})

sectionLPM.on("mouseleave", function () {
    let a = $(this).find(".description-content")
    console.log($(a))
    $(this).find(".video-lpm")[0].pause()
    $(this).find(".img-section-lpm").addClass("active")

    if (window.innerWidth > 1400) {
        $(this).find(".description-content").animate({
            height: $(this).find(".description-content").attr("data-height")
        }, {duration: 500, queue: false}, () => {

        })
    }
})

let geo_lon = 30.316425
let geo_lat = 59.968142
let name_company = "ХОЛДИНГ ЛЕНПОЛИГРАФМАШ"
if (geo_lat && geo_lon && name_company) {
    var data = {
        type: "FeatureCollection",
        features: [
            {
                id: "1",
                type: "Feature",
                geometry: {
                    type: "Point",
                    coordinates: [geo_lat, geo_lon]
                },
                properties: {
                    iconCaption: name_company,
                    hasBalloon: false
                }
            }
        ]
    }
}




ymaps.ready(initMap)

function initMap() {

    let inputSearch = new ymaps.control.SearchControl({
        options: {
            visible: false
        }
    })

    let zoomControl = new ymaps.control.ZoomControl({
        options: {
            visible: true
        }
    })




    var myMap = new ymaps.Map("map", {
        // Координаты центра карты
        center: [geo_lat, geo_lon],
        controls: [inputSearch, zoomControl],
        // Масштаб карты (город)
        zoom: 16,

    },{suppressMapOpenBlock: true});

    let objectManager = new ymaps.ObjectManager({
        // Чтобы метки начали кластеризоваться, выставляем опцию.
        clusterize: true,
        // ObjectManager принимает те же опции, что и кластеризатор.
        gridSize: 32,
        clusterHasBalloon: false,
        clusterDisableClickZoom: true
    });


    objectManager.objects.options.set('preset', 'islands#greenDotIconWithCaption');
    objectManager.objects.options.set('iconColor', '#075055');
    objectManager.clusters.options.set('clusterPreset', 'islands#greenClusterIcons');
    objectManager.clusters.options.set('clusterIconColor', '#075055');

    myMap.behaviors.disable('scrollZoom')

    myMap.geoObjects.add(objectManager);

    if (data !== undefined) {
        objectManager.add(data)
    }


    // myMap.geoObjects.add(new ymaps.Placemark([59.93, 30.31], {
    //     iconCaption: "Название филиала очень длинное, которое не влезет сюда",
    //     hasBalloon: false
    // },{
    //     preset: 'islands#greenDotIconWithCaption',
    //     iconColor: "#000000"
    // }))
}


